import {
  Link,
} from "react-router-dom"

function Footer() {
  return <div className={`mt-6 flex flex-col items-center`}>
    <div className={`w-full max-w-2xl px-4 py-4  flex  md:flex-row flex-col gap-4 md:gap-8 items-center justify-center`}>


      <div className={`flex flex-row gap-4 text-white items-center text-xs`}>
        <Link to='http://kakapolabs.co.uk'><img src={`/circle-kakapo-outline.svg`} alt='Kakapo Labs Logo' className={`w-8`} /></Link>
        <p>This is a <a href='https://kakapolabs.co.uk' className={`underline`}>Kakapo Labs</a> project.
      &copy; W&nbsp;O&nbsp;Lahr Ltd.  Background photo by <a href="https://unsplash.com/@thedlkr?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className={`underline`}>DLKR</a> on <a href="https://unsplash.com/photos/EJHiB8oRzYc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" className={`underline`}>Unsplash</a>.
        </p>
      </div>


      <div className={`h-6 items-center justify-end flex flex-row gap-3`}>
        <a href='https://twitter.com/KakapoLabs_'><img alt='Twitter' src='/social-twitter.svg' className={`h-4`} /></a>
        <a href='https://www.instagram.com/kakapo.labs/'><img alt='Instagram' src='/social-instagram.svg' className={`h-4`} /></a>
        <a href='https://uk.linkedin.com/company/kakapo-labs-london'><img alt='LinkedIn' src='/social-linkedin.svg' className={`h-4`} /></a>
        <a href='https://www.youtube.com/channel/UCL4AhBbD5JByMA5-4ouIluw'><img alt='YouTube' src='/social-youtube.svg' className={`h-4`} /></a>

      </div>





    </div>
  </div>
}

export default Footer