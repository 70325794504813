
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"

type Props = {
  content: { [key: string]: any }
  direction: string
  section: string
  subsection: string
}


function NavigationButton({
  content,
  direction,
  section,
  subsection
}: Props) {


  const selectedSection = parseInt(section)
  const selectedSubsection = parseInt(subsection)
  const totalSections = content.sections.length
  const totalSubsectionsInThisSection = content.sections[selectedSection].subsections ? content.sections[selectedSection].subsections.length - 1 : 0


  const nextSection = selectedSection === totalSections - 1 ? null : selectedSection + 1
  const nextSubsection = selectedSubsection === totalSubsectionsInThisSection ? null : selectedSubsection + 1
  const previousSection = selectedSection === 0 ? null : selectedSection - 1
  const previousSubsection = selectedSubsection === 0 ? null : selectedSubsection - 1


  let canNavigate = false
  let navigateToSection = selectedSection
  let navigateToSubsection = selectedSubsection



  if (direction === 'next') {
    if (nextSection !== null || nextSubsection !== null) {
      canNavigate = true
    }

    if (nextSubsection !== null) {
      navigateToSubsection = selectedSubsection + 1
    } else {
      navigateToSection = selectedSection + 1
      navigateToSubsection = 0
    }
  }



  if (direction === 'back') {
    if (previousSection !== null || previousSubsection !== null) {
      canNavigate = true
    }

    if (previousSection !== null && previousSubsection === null) {
      navigateToSection = selectedSection - 1
    }

    if (previousSubsection === null) {
      navigateToSubsection = content.sections[previousSection || 0].subsections.length - 1
    } else {
      navigateToSubsection = previousSubsection
    }
  }




  return <div className={`w-full`}>
    {
      canNavigate && <div className={`bg-brandblue hover:bg-brandgreen py-1 px-2 text-center rounded drop-shadow-xl cursor-pointer`}>
        {/* <p>{navigateToSection}/{navigateToSubsection}</p> */}
        <Link to={`/guide/${navigateToSection}/${navigateToSubsection}`}><p className={`text-white font-bungee`}>{direction}</p>
          <p className={`text-white text-xs`}>{content.sections[navigateToSection]['navigation-button-text'] || content.sections[navigateToSection].title}:<br />
          {content.sections[navigateToSection].subsections[navigateToSubsection] && <>{content.sections[navigateToSection].subsections[navigateToSubsection]['navigation-button-text'] || content.sections[navigateToSection].subsections[navigateToSubsection].title}</>}
          </p>
        </Link>
      </div>
    }
  </div>
}

export default NavigationButton