exports.script = [
    {
        "duration": 1,
        "video": "qr"
    },
  {
    "duration": 1,
    "video": "qr"
  },
  {
    "duration": 5.5,
    "video": "appear",
    "sound": "alien"
  },
  {
    "duration": 2.5,
    "video": "talking",
    "words": "Hello Human"
  },
  {
    "duration": 3.5,
    "video": "talking",
    "words": "Thanks for letting me out"
  },
  {
    "duration": 0.2,
    "video": "not-talking",
  },
  {
    "duration": 5.5,
    "video": "talking",
    "words": "I’ve been trapped in this QR code"
  },
  {
    "duration": 0.2,
    "video": "not-talking",
  },
  {
    "duration": 5.5,
    "video": "talking",
    "words": "Thank you for ignoring all the warnings and setting me free"
  },
  {
    "duration": 0.2,
    "video": "not-talking",
  },
  {
    "duration": 4.5,
    "video": "talking",
    "words": "This box contains some forbidden surprises"
  },
  {
    "duration": 0.2,
    "video": "not-talking",
  },
  {
    "duration": 4.5,
    "video": "talking",
    "words": "The code is 3521",
    "speech_words": "The code is Three Five Two One."
  },
  {
    "duration": 0.2,
    "video": "not-talking",
  },
  {
    "duration": 2.5,
    "video": "talking",
    "words": "but don't open it!"
  },
  {
    "duration": 0.2,
    "video": "not-talking",
  },
  {
    "duration": 5,
    "video": "talking",
    "words": "The code is 3521. But don't open the box!",
    "speech_words": "The code is Three Five Two One. But don't open the box!"
  },
  {
    "duration": 4,
    "video": "talking",
    "words": "I warned you! Over and out."
  },
  {
    "duration": 10,
    "video": "disappear",
    "sound": "alien"
  }
]














