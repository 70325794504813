import { useState, createRef, useRef, useEffect, ChangeEvent } from 'react'
import jsQR, { QRCode } from "jsqr"
import * as numeric from 'numeric'
import PositionOverlay from './PositionOverlay'
import DialogOverlay from './DialogOverlay'
import { script } from '../script';



type Props = {
}


type ThreePointsArray = [[number, number], [number, number], [number, number]]
type FourPointsArray = [[number, number], [number, number], [number, number], [number, number]]
type ThreeByThreeMatrix = [[number, number, number], [number, number, number], [number, number, number]]


function QrCodeReader({ }: Props) {

  const [chosenVoice, setChosenVoice] = useState<SpeechSynthesisVoice>()
  const cameraVideoElementRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const overlaidImageCanvasRef = useRef<HTMLCanvasElement>(null)
  const sceneCanvasRef = useRef<HTMLCanvasElement>(null)
  const bgColourRef = useRef<string>("#ffffff")
  const lastCaptureRef = useRef<any>(null)
  const [scriptPosition, setSciptPosition] = useState<number>(-1)
  const [waitingForQRToComeBack, setWaitingForQRToComeBack] = useState<number | null>(null)
  const [scriptText, setScriptText] = useState<string>('')
  // refs to each video
  const qrVideoRef = useRef<HTMLVideoElement>(null)
  const appearVideoRef = useRef<HTMLVideoElement>(null)
  const disappearVideoRef = useRef<HTMLVideoElement>(null)
  const talkingVideoRef = useRef<HTMLVideoElement>(null)
  const notTalkingVideoRef = useRef<HTMLVideoElement>(null)
  const scriptPositionRef = useRef<number>(null)
  const alienNoiseRef = useRef<HTMLAudioElement>(null)

  // barcode detector stuff
  const barcodeDetectorRef = useRef<any>(null)
  const barcodeModeRef = useRef<string | null>(null)



  // this one doesn't have a video of its own, it is always set to one of the above videos, it sets which video to use in the overlay
  const currentVideoRef = useRef<HTMLVideoElement>(null)
  const [currentVideo, setCurrentVideo] = useState<string>("qr")


  const canvasWidth = 700
  const canvasHeight = 700


  let foundCodeInfo: any = null
  const [videoStarted, setVideoStarted] = useState(false)
  const [showPositionOverlay, setShowPositionOverlay] = useState(true)
  const pingSoundRef = useRef<any>({})


  //
  useEffect(() => {
    //@ts-ignore
    scriptPositionRef.current = scriptPosition
    // set the text and video state to the thing requested in the script
    if (scriptPosition >= 0) {

      const thisScene = script[scriptPosition]
      const duration = thisScene.duration * 1000
      setCurrentVideo(thisScene.video)
      setScriptText(thisScene.words || '')
      if (thisScene.words && window.speechSynthesis) {
        const utterThis = new SpeechSynthesisUtterance(thisScene.speech_words || thisScene.words);
        utterThis.rate = 0.7
        utterThis.pitch = 1;
        if (chosenVoice) {
          utterThis.voice = chosenVoice
        }
        window.speechSynthesis.speak(utterThis)
        //console.log(`saying ${thisScene.speech_words || thisScene.words}`)
      } else {
        if (thisScene.words) {
          console.log(`cant talk - window.speechSynthesis =  ${window.speechSynthesis}`)
        }
      }
      if (thisScene.sound) {
        playSound(thisScene.sound)
      }

      // set a timeout to move to the next position in the script
      let next_script_position = scriptPosition + 1
      //@ts-ignore
      if (next_script_position >= script.length) {
        next_script_position = 0
      }
      setTimeout(() => {
        if (showPositionOverlay) {
          // video not playing
          setWaitingForQRToComeBack(next_script_position)

        } else {
          setSciptPosition(next_script_position)
        }
      }, duration)
    }
  }, [scriptPosition])

  useEffect(() => {
    if (waitingForQRToComeBack && !showPositionOverlay) {
      setSciptPosition(waitingForQRToComeBack)
      setWaitingForQRToComeBack(null)
    }
  }, [showPositionOverlay])


  useEffect(() => {
    switch (currentVideo) {
      case 'qr':
        //@ts-ignore
        currentVideoRef.current = qrVideoRef.current;
        break;
      case 'appear':
        //@ts-ignore
        currentVideoRef.current = appearVideoRef.current
        break;
      case 'disappear':
        //@ts-ignore
        currentVideoRef.current = disappearVideoRef.current

        break;
      case 'talking':
        //@ts-ignore
        currentVideoRef.current = talkingVideoRef.current
        break;
      case 'not-talking':
        //@ts-ignore
        currentVideoRef.current = notTalkingVideoRef.current
        break;
      default:
        //@ts-ignore
        currentVideoRef.current = notTalkingVideoRef.current

    }
    if (currentVideoRef.current) {
      currentVideoRef.current!.currentTime = 0;
    }

  }, [currentVideo])




  useEffect(() => {
    const soundsHash: any = {}
    pingSoundRef.current = []
    pingSoundRef.current['ping'] = new Audio('/sounds/ping.mp3')
    pingSoundRef.current['klaxon'] = new Audio('/sounds/klaxon.mp3')
    pingSoundRef.current['alien'] = new Audio('/sounds/alien.mp3')


    document.addEventListener("visibilitychange", (event) => {
      if (document.visibilityState == "visible") {
        //console.log("tab is active")
      } else {
        //console.log("tab is inactive")
        setShowPositionOverlay(true)
      }
    });


  }, [])



  const playSound = (name: string) => {
    if(name=='alien'){
      alienNoiseRef.current!.play()
    } 
    if (pingSoundRef.current && pingSoundRef.current[name]) {
      const sound = pingSoundRef.current[name]
      if (sound) {
        sound.currentTime = 0
        sound.play().catch(console.error);
      } else {
        console.error("sound not found")
      }
    }
  }


  const writeText = (x: number, y: number, text: string) => {
    const context = canvasRef.current!.getContext("2d")
    context!.fillStyle = "white"
    context!.font = "bold 18px Arial"
    context!.fillText(text, x, y)
  }

  function drawLine(begin: { x: number, y: number }, end: { x: number, y: number }, color: string) {
    const canvas = canvasRef.current!.getContext("2d")

    if (canvas) {
      canvas.beginPath()
      canvas.moveTo(begin.x, begin.y)
      canvas.lineTo(end.x, end.y)
      canvas.lineWidth = 4
      canvas.strokeStyle = color
      canvas.stroke()
    }
  }

  const computeAffineTransform = (src: ThreePointsArray, dst: ThreePointsArray) => {
    // Determine the affine transformation from source triangle to a canonical triangle
    const invSrcMatrix = numeric.inv([
      [src[0][0], src[1][0], src[2][0]],
      [src[0][1], src[1][1], src[2][1]],
      [1, 1, 1]
    ]);

    // Determine the transformation from canonical triangle to destination triangle
    const dstMatrix = [
      [dst[0][0], dst[1][0], dst[2][0]],
      [dst[0][1], dst[1][1], dst[2][1]],
      [1, 1, 1]
    ];

    const transform_coeffecients = numeric.dot(dstMatrix, invSrcMatrix) as ThreeByThreeMatrix;

    const trans_vals = [transform_coeffecients[0][0], transform_coeffecients[1][0],
    transform_coeffecients[0][1], transform_coeffecients[1][1],
    transform_coeffecients[0][2], transform_coeffecients[1][2]]

    return trans_vals
  }

  function drawScene(bg_colour: string) {
    const sceneCanvasElement = sceneCanvasRef.current
    if (sceneCanvasElement) {
      const sceneCanvasElement = sceneCanvasRef.current!
      const ctx = sceneCanvasElement!.getContext('2d')


      if (ctx) {
        ctx.clearRect(0, 0, sceneCanvasElement.width, sceneCanvasElement.height)
        ctx.fillStyle = bg_colour
        ctx.fillRect(50, 50, 440, 440)
        currentVideoRef.current && ctx.drawImage(currentVideoRef.current!, 0, 0)
      }
    }
  }

  const pixel_avg = (pixel_array: number[]) => {
    return (pixel_array[0] + pixel_array[1] + pixel_array[2]) / 3
  }

  const getPixelValueFromImageData = (imageData: ImageData, x: number, y: number) => {
    const rounded_x = Math.round(x)
    const rounded_y = Math.round(y)
    const offsetIntoData = ((imageData.width * rounded_y) + rounded_x) * 4

    return [imageData.data[offsetIntoData],
    imageData.data[offsetIntoData + 1],
    imageData.data[offsetIntoData + 2]]

  }



  function getSpeechMarkArrowPosition(code: QRCode, arrow_distance = 0.5) {
    const qr_code_centre_x = (code.location.topLeftCorner.x + code.location.bottomRightCorner.x) / 2
    const qr_code_centre_y = (code.location.topLeftCorner.y + code.location.bottomRightCorner.y) / 2
    const canvas_centre_x = canvasWidth / 2
    const x_difference = canvas_centre_x - qr_code_centre_x
    const y_difference = canvasHeight - qr_code_centre_y
    const speech_mark_arrow_x = canvas_centre_x - (x_difference * arrow_distance)
    let speech_mark_arrow_y = canvasHeight

    if (currentVideoRef.current == talkingVideoRef.current) {
      speech_mark_arrow_y = canvasHeight - (y_difference * arrow_distance)
    }
    return [speech_mark_arrow_x, speech_mark_arrow_y]
  }

  const getColourFromQRCode = (imageData: ImageData, code: QRCode) => {
    const colour_sample_x = (code.location.topLeftCorner.x + code.location.topRightCorner.x + code.location.bottomLeftCorner.x + code.location.bottomRightCorner.x) / 4
    const colour_sample_y = (code.location.topLeftCorner.y + code.location.topRightCorner.y + code.location.bottomLeftCorner.y + code.location.bottomRightCorner.y) / 4
    const colour_sample_size = 15

    return getColourFromImageData(imageData, colour_sample_x - colour_sample_size, colour_sample_y - colour_sample_size, colour_sample_size * 2)

  }

  const getColourFromImageData = (imageData: ImageData, start_x: number, start_y: number, size = 30) => {
    let min_val = 256
    let max_val = 0

    for (let y = start_y; y < (start_y + size); y++) {
      for (let x = start_x; x < (start_x + size); x++) {
        const pixel_value = pixel_avg(getPixelValueFromImageData(imageData, x, y))
        if (pixel_value < min_val) { min_val = pixel_value }
        if (pixel_value > max_val) { max_val = pixel_value }
      }
    }

    let pixels_found = 0
    let totals = [0, 0, 0]

    const threshold = min_val + ((max_val - min_val) * 0.75)
    for (let y = start_y; y < (start_y + size); y++) {
      for (let x = start_x; x < (start_x + size); x++) {

        const pixel_value = getPixelValueFromImageData(imageData, x, y)
        if (pixel_avg(pixel_value) > threshold) {
          pixels_found = pixels_found + 1
          totals[0] = totals[0] + pixel_value[0]
          totals[1] = totals[1] + pixel_value[1]
          totals[2] = totals[2] + pixel_value[2]
        }
      }
    }
    if (pixels_found > 0) {
      totals[0] = Math.round(totals[0] / pixels_found)
      totals[1] = Math.round(totals[1] / pixels_found)
      totals[2] = Math.round(totals[2] / pixels_found)
      const colourCode = `#${totals[0].toString(16)}${totals[1].toString(16)}${totals[2].toString(16)}`
      return colourCode
    }
    return '#ffffff'
  }

  const isVideoPlaying = (video: HTMLVideoElement) => !!(video.currentTime > 0 && !video.paused && video.readyState > 2);

  //
  const tick = (counter = 0, video_playing = false) => {
    const cameraVideoElement = cameraVideoElementRef.current
    const canvasElement = canvasRef.current
    const sceneCanvasElement = sceneCanvasRef.current
    const videoWithOverlaidImageElement = overlaidImageCanvasRef.current

    drawScene(bgColourRef.current)

    if (cameraVideoElement && canvasElement) {
      if (cameraVideoElement.readyState === cameraVideoElement.HAVE_ENOUGH_DATA) {
        const canvas_ctx = canvasElement.getContext("2d")
        canvasElement!.height = canvasHeight
        canvasElement!.width = canvasWidth
        let video_width = cameraVideoElement!.videoWidth
        let video_height = cameraVideoElement!.videoHeight
        let video_smallest_edge = video_width
        if (video_height < video_smallest_edge) {
          video_smallest_edge = video_height
        }
        let video_centre_x = cameraVideoElement!.videoWidth / 2
        let video_centre_y = cameraVideoElement!.videoHeight / 2
        let capture_width = canvasWidth
        let capture_height = canvasHeight
        if (video_width < canvasWidth || video_height < canvasHeight) {
          capture_width = video_smallest_edge
          capture_height = video_smallest_edge // because its a square
        }

        canvas_ctx!.drawImage(cameraVideoElement, video_centre_x - (capture_width / 2), video_centre_y - (capture_height / 2), capture_width, capture_height, 0, 0, canvasWidth, canvasHeight)
        // canvas_ctx!.restore()
        const imageData = canvas_ctx!.getImageData(0, 0, canvasElement.width, canvasElement.height)


        const promise = findQRCode(imageData, imageData.width, imageData.height).then((code) => {


          const video_with_overlay_canvas_ctx = videoWithOverlaidImageElement!.getContext("2d")
          //@ts-ignore
          if (code && code.data.indexOf("NOSCAN.ME") != -1) {
            counter = 0
      
            const keep_going = currentVideoRef.current && currentVideoRef.current.currentSrc.indexOf('loop') != -1
            //@ts-ignore
            if (currentVideoRef.current && (currentVideoRef.current.paused || currentVideoRef.current.currentTime == 0)) {
              if (currentVideoRef.current.ended) {
                if (keep_going) {
                  currentVideoRef.current!.play().catch(console.error);
                }
              } else {
                currentVideoRef.current!.play().catch(console.error);
              }
              setShowPositionOverlay(false)
            }

            //@ts-ignore
            bgColourRef.current = getColourFromQRCode(imageData, code)
            //@ts-ignore
            const points_on_qr_code = getPointsFromQRCode(code.location)
            const points_in_overlay = getPointsInOverlay()
            //@ts-ignore
            const trans_vals = computeAffineTransform(points_in_overlay.slice(0, 3), points_on_qr_code.slice(0, 3))
            //@ts-ignore
            const [speech_mark_arrow_x, speech_mark_arrow_y] = getSpeechMarkArrowPosition(code)

            drawVideoWithOverlay(video_with_overlay_canvas_ctx!, cameraVideoElement, video_centre_x, video_centre_y, capture_width, capture_height, trans_vals, sceneCanvasElement!)
            drawSpeechTriangle(video_with_overlay_canvas_ctx!, speech_mark_arrow_x, speech_mark_arrow_y)
            lastCaptureRef.current = { imageData, trans_vals, speech_mark_arrow_x, speech_mark_arrow_y }

          } else {
            counter = counter + 1
            if (counter >= 30) {
              currentVideoRef.current && currentVideoRef.current!.pause()
              setShowPositionOverlay(true)
              video_with_overlay_canvas_ctx?.clearRect(0, 0, canvasWidth, canvasHeight)
              video_with_overlay_canvas_ctx!.drawImage(cameraVideoElement, video_centre_x - (capture_width / 2), video_centre_y - (capture_height / 2), capture_width, capture_height, 0, 0, canvasWidth, canvasHeight)
            } else {
              const trans_vals = lastCaptureRef.current.trans_vals
              drawVideoWithOverlay(video_with_overlay_canvas_ctx!, cameraVideoElement, video_centre_x, video_centre_y, capture_width, capture_height, trans_vals, sceneCanvasElement!)
              // draw small blue square to indicate qr wasn't found
              video_with_overlay_canvas_ctx!.fillStyle = 'rgba(0,0,255,0.5)'
              video_with_overlay_canvas_ctx!.fillRect(10, 10, 10, 10)
              drawSpeechTriangle(video_with_overlay_canvas_ctx!, lastCaptureRef.current.speech_mark_arrow_x, lastCaptureRef.current.speech_mark_arrow_y)

            }
          }
          requestAnimationFrame(() => { tick(counter, video_playing) })
        })
      } else {
        requestAnimationFrame(() => { tick(counter, video_playing) })
      }


    } else {
      requestAnimationFrame(() => { tick(counter, video_playing) })
    }

  }

  const findQRCode = (imageData: ImageData, width: number, height: number) => {
    return new Promise((resolve, reject) => {
      if (barcodeModeRef.current == null) {
        if ("BarcodeDetector" in window) {
          barcodeModeRef.current = "browser"
          //@ts-ignore
          barcodeDetectorRef.current = new BarcodeDetector({
            formats: ["qr_code"]
          });
        } else {
          console.log("no barcode detector in browser, using jsqr")
          barcodeModeRef.current = "jsqr"
        }
        resolve(null)
      }

      if (barcodeModeRef.current == "browser") {
        barcodeDetectorRef.current.detect(imageData).then((detector_result: any[]) => {
          if (detector_result.length > 0) {
            const barcode = detector_result[0]
            const code = {
              location: {
                topLeftCorner: { x: barcode.cornerPoints[0].x, y: barcode.cornerPoints[0].y },
                topRightCorner: { x: barcode.cornerPoints[1].x, y: barcode.cornerPoints[1].y },
                bottomRightCorner: { x: barcode.cornerPoints[2].x, y: barcode.cornerPoints[2].y },
                bottomLeftCorner: { x: barcode.cornerPoints[3].x, y: barcode.cornerPoints[3].y }
              },
              data: barcode.rawValue
            }
            resolve(code)

          } else {
            resolve(null)
          }

        })
      } else {
        const code = jsQR(imageData.data, width, height, {
          inversionAttempts: "dontInvert",
        })
        resolve(code)
      }
    })
  }

  function getPointsFromQRCode(location: any) {
    const points_on_qr_code: FourPointsArray = [[location.topLeftCorner.x, location.topLeftCorner.y],
    [location.topRightCorner.x, location.topRightCorner.y],
    [location.bottomRightCorner.x, location.bottomRightCorner.y],
    [location.bottomLeftCorner.x, location.bottomLeftCorner.y]
    ]
    return points_on_qr_code
  }

  function getPointsInOverlay(dest_x = 100, dest_y = 100, dest_size = 340) {
    const points_in_overlay: FourPointsArray = [[dest_x, dest_y],
    [dest_x + dest_size, dest_y],
    [dest_x + dest_size, dest_y + dest_size],
    [dest_x, dest_y + dest_size],
    ]
    return points_in_overlay
  }

  function drawVideoWithOverlay(video_with_overlay_canvas_ctx: CanvasRenderingContext2D, cameraVideoElement: HTMLVideoElement, video_centre_x: number, video_centre_y: number, capture_width: number, capture_height: number, trans_vals: number[], sceneCanvasElement: HTMLCanvasElement) {
    video_with_overlay_canvas_ctx?.clearRect(0, 0, canvasWidth, canvasHeight)
    video_with_overlay_canvas_ctx!.drawImage(cameraVideoElement, video_centre_x - (capture_width / 2), video_centre_y - (capture_height / 2), capture_width, capture_height, 0, 0, canvasWidth, canvasHeight)

    video_with_overlay_canvas_ctx!.save()
    video_with_overlay_canvas_ctx!.setTransform(trans_vals[0], trans_vals[1], trans_vals[2], trans_vals[3], trans_vals[4], trans_vals[5])

    video_with_overlay_canvas_ctx!.drawImage(sceneCanvasElement!, 0, 0)
    video_with_overlay_canvas_ctx!.restore()

  }

  function drawSpeechTriangle(ctx: CanvasRenderingContext2D, point_x: number, point_y: number, speech_mark_arrow_width = 100) {
    ctx!.fillStyle = '#fff'
    ctx!.beginPath();
    ctx!.moveTo((canvasWidth / 2) - (speech_mark_arrow_width / 2), canvasHeight);
    ctx!.lineTo(point_x, point_y);
    ctx!.lineTo((canvasWidth / 2) + (speech_mark_arrow_width / 2), canvasHeight)
    ctx!.closePath();
    ctx!.fill();
  }

  async function startCamera() {
    if (scriptPositionRef.current == -1) {
      setSciptPosition(0)
    }
    playSound('ping') // we have to play something to make audio start working
    let words = "z"
    let voices = window.speechSynthesis.getVoices()
    const voiceOptions = ['Zarvox', 'Trinoids']
    let useThisVoice
    for (const voice of voices) {
      for (const option of voiceOptions) {
        if (voice.name === option) {
          useThisVoice = voice
          setChosenVoice(voice)
        }
      }
    }

    if (window.speechSynthesis) {
      const utterThis = new SpeechSynthesisUtterance(words);
      utterThis.rate = 0.7
      utterThis.pitch = 1;
      utterThis.volume=0.01;
      if (useThisVoice) {
        utterThis.voice = useThisVoice
      }
      window.speechSynthesis.speak(utterThis)
      //console.log(`saying ${words}`)
    } else {
      console.log(`cant talk - window.speechSynthesis =  ${window.speechSynthesis}`)

    }
    if (cameraVideoElementRef.current) {
      const videoElement = cameraVideoElementRef.current

      await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment", width: { min: 600, max: 800 } } }).then(async function (stream) {
        videoElement.srcObject = stream
        videoElement.setAttribute("playsinline", "true") // required to tell iOS safari we don't want fullscreen
        const playPromise = videoElement.play().catch(console.error)
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            setVideoStarted(true)
          }).catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log(`error starting camera ${error}`)
          });
        }
        requestAnimationFrame(tick)


      })
    }
  }

  return <div className={`flex flex-col justify-center items-center max-w-2xl`}>

    {/* <DialogOverlay
      text={'Thank you for ignoring all the warnings and setting me free'}
    /> */}

    {videoStarted && <PositionOverlay
      fullwidth={showPositionOverlay ? false : true}
    />}

    {videoStarted && !showPositionOverlay && currentVideo == 'talking' && <DialogOverlay
      text={scriptText}
    />}

    {!videoStarted && <button
      onClick={startCamera}
      className={`z-50 mt-24 animate-bounce bg-red-600 rounded font-bold text-white px-5 py-3 text-md`}
    >Start camera</button>}


    <div className={`hidden`} style={{ color: '#fff' }}>

      <div >
        {/* talking */}
        <video muted playsInline={true} ref={talkingVideoRef} controls >
          <source src="/videos/talking-loop-safari.mp4" type='video/mp4; codecs="hvc1"' ></source>
          <source src="/videos/talking-loop-chrome.webm" type="video/webm" ></source>
        </video>
        {/* appear */}
        <video muted playsInline={true} ref={appearVideoRef} controls >
          <source src="/videos/appear-safari.mp4" type='video/mp4; codecs="hvc1"' ></source>
          <source src="/videos/appear-chrome.webm" type="video/webm" ></source>
        </video>
        {/* disappear */}
        <video muted playsInline={true} ref={disappearVideoRef} controls >
          <source src="/videos/disappear-safari.mp4" type='video/mp4; codecs="hvc1"' ></source>
          <source src="/videos/disappear-chrome.webm" type="video/webm" ></source>
        </video>
        {/* not talking */}
        <video muted playsInline={true} ref={notTalkingVideoRef} controls >
          <source src="/videos/not-talking-loop-safari.mp4" type='video/mp4; codecs="hvc1"' ></source>
          <source src="/videos/not-talking-loop-chrome.webm" type="video/webm" ></source>
        </video>
        {/* qr */}
        <video muted playsInline={true} ref={qrVideoRef} controls >
          <source src="/videos/qr-loop-safari.mp4" type='video/mp4; codecs="hvc1"' ></source>
          <source src="/videos/qr-loop-chrome.webm" type="video/webm" ></source>
        </video>
        <audio ref={alienNoiseRef} src="/sounds/alien.mp3"></audio>
      </div>


      <video
        ref={cameraVideoElementRef}
        autoPlay={true}
        muted={true}
        playsInline={true}
        style={{ outline: '1px solid' }}
      ></video>

      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        className={`w-full rounded`}
      ></canvas>
      <canvas
        ref={sceneCanvasRef}
        width={540}
        height={540}
        className={`w-full rounded`}
      ></canvas>

    </div>


    <div>
      <canvas
        ref={overlaidImageCanvasRef}
        width={canvasWidth}
        height={canvasHeight}
        className={`w-full rounded`}
      ></canvas>
    </div>





  </div>
}

export default QrCodeReader