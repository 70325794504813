import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import Modal from './Modal'


type Props = {
  content: { [key: string]: any }
  section: string
  subsection: string
}

type ModalContentProps = {
  popup: { [key: string]: any }
}


function ModalContent({
  popup
}: ModalContentProps) {


  return <div className={`flex flex-col items-center`}>
    <p className={`text-2xl pb-2 text-center text-bold font-bungee`}>{popup.title}</p>
    {popup.subtitle && <p className={`text-md text-center text-bold font-bungee`}>{popup.subtitle}</p>}
    <p className={`text-md text-center`}>{popup.description}</p>

    <img className={`w-4/5 py-4`} src={`/${popup.image}`} alt='' />

    {popup.subpoints && <>
      <img className={`w-full`} src={`/drop-shadow-top.png`} alt='' />
      <div className={`my-4 flex flex-col gap-6`}> {popup.subpoints.map((subpoint: any, index: number) => {
        return <div key={index} className={`flex flex-row gap-4`}>
          <div className={`w-2/5`}>
            <p className={`text-xs font-bold text-brandgreen`}>{subpoint.title}</p>
          </div>
          <div className={`w-3/5`}>
            {subpoint.description && <p className={`text-xs`}>{subpoint.description}</p>}
            {subpoint.list && <ul className={`ml-4`}>{subpoint.list.map((listitem: any, index: number) => {
              return <li key={index} className={`text-xs list-disc`}>{listitem}</li>
            })}</ul>}
          </div>

        </div>
      })}</div>
      <img className={`w-full`} src={`/drop-shadow-bottom.png`} alt='' />
    </>}

    {popup.cta && <div className={`flex flex-row gap-2 items-center`}>
      <Link className={`text-md text-center text-bold font-bungee`} to={`${popup.cta.url}`} >{popup.cta.text}</Link>
      <img className={`w-3 h-5`} src={`/right-arrow-brandblue.png`} alt='' />
    </div>}

  </div>
}


function SubSection({
  content,
  section,
  subsection
}: Props) {


  const [showModal, setShowModal] = useState<number | null>(null)
  const popups = content.sections[section].subsections[subsection]['popups']

  return <div className={`w-full flex flex-col`}>



    {popups && <div className={`flex flex-row justify-center flex-wrap gap-4 `}>
      {popups.map((popup: any, index: number) => {
        return <div key={index}>
          {showModal === index && <Modal
            setShowModal={setShowModal}
            showCloseButton={true}
            closeButtonValue={null}
          >
            <ModalContent
              popup={popup}
            />
          </Modal>}

          <div
            key={index}
            className={`max-w-xs md:w-44 flex flex-col gap-2 p-2 bg-white border-2 border-white hover:border-brandgreen border-dotted text-center rounded hover:drop-shadow-md cursor-pointer `}
            onClick={() => setShowModal(index)}
          >
            <img className={`w-full`} src={`/${popup.image}`} alt='' />
            <p className={`text-md text-bold font-bungee`}>{popup.title}</p>
          </div>
        </div>
      })}
    </div>}
  </div>
}

export default SubSection