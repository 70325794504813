
import { useState } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import content from './content.json'
import Header from './components/Header'
import Footer from './components/Footer'
import Section from './components/Section'
import QrCodeReader from './components/QRCodeReader'


function App() {

  let voices = window.speechSynthesis.getVoices()

  return <div className={`h-screen flex flex-col justify-between`}>
    <Router>
      <Header />
      <Routes>

        <Route
          path='/*'
          element={<div className={`mx-4 mt-8 relative flex flex-col items-center justify-center`}>
            <QrCodeReader
             />
          </div>} />

        <Route
          path="guide/:section/:subsection"
          element={<Section
            content={content}
          />} />

      </Routes>
      <Footer />
    </Router>

  </div>
}

export default App
