
type Props = {
  text: string
}


function DialogOverlay({ text }: Props) {
  return <div className={`absolute top-4 left-0 z-10 w-full h-full flex flex-col items-center justify-end px-8`}>
    <div className={`max-w-lg`}>
      <div className={`bubble rounded px-3 py-3`}>
        <p className={`text-center text-sm font-bungee`}>{text}</p>
      </div>
    </div>
  </div>
}

export default DialogOverlay