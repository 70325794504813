
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import SubSection from './SubSection'
import NavigationButton from './NavigationButton'
import background from '../background.jpg'


type Props = {
  content: { [key: string]: any }
}

function Section({
  content
}: Props) {

  let { section, subsection } = useParams()
  let thisSection = null
  let thisSubsection = null



  if (section && typeof parseInt(section) === 'number') {
    thisSection = parseInt(section)
  }
  if (subsection && typeof parseInt(subsection) === 'number') {
    thisSubsection = parseInt(subsection)
  }




  return <>

    <div className={`w-full md:hidden fixed bottom-0 flex flex-col =p-4 border-t-1 z-10`}>
      <img className={`w-full`} src='/drop-shadow-top.png' />
      <div className={`w-full bg-white p-2 flex flex-row gap-2`}>
        <NavigationButton
          content={content}
          direction={'back'}
          section={`${thisSection}`}
          subsection={`${subsection}`}
        />
        <NavigationButton
          content={content}
          direction={'next'}
          section={`${thisSection}`}
          subsection={`${subsection}`}
        />
        </div>
      </div>


    {thisSection !== null && thisSubsection !== null && content.sections[thisSection] && content.sections[thisSection] && content.sections[thisSection].subsections[thisSubsection] ?
      <div className={`w-full flex flex-col items-center`}>
        <div className={`w-full flex flex-col items-center p-4 bg-brandgreen bg-cover`} style={{ backgroundImage: `url(${background})` }}>
          <div className={`w-full max-w-2xl px-4 py-4  flex flex-row gap-4 items-start justify-between`}>

            <div className={`w-full text-center`}>
              <h2 className={`text-4xl text-bold font-bungee pb-2`}>{content.sections[thisSection].title}</h2>
              <h3 className={`text-2xl text-bold font-bungee pb-2`}>{content.sections[thisSection].subsections[thisSubsection].title}</h3>
              <p className={``}>{content.sections[thisSection].subsections[thisSubsection].description}</p>
            </div>

          </div>
        </div>

        <div className={`w-full max-w-2xl px-4 py-4  flex flex-row gap-4 items-start justify-between`}>

          <div className={`w-48 pt-2 hidden md:block`}>
            <NavigationButton
              content={content}
              direction={'back'}
              section={`${thisSection}`}
              subsection={`${subsection}`}
            />
          </div>
          <SubSection
            section={`${thisSection}`}
            subsection={`${subsection}`}
            content={content}
          />
          <div className={`w-48 pt-2 hidden md:block`}>
            <NavigationButton
              content={content}
              direction={'next'}
              section={`${thisSection}`}
              subsection={`${subsection}`}
            />
          </div>

        </div>

      </div>
      :
      <p>Sorry - could not find this section</p>
    }


  </>
}


export default Section