type Props = {
  fullwidth: boolean
}

function PositionOverlay({ fullwidth }: Props) {
  return <div className={`animate-pulse absolute top-0 left-0 z-10 w-full h-full flex flex-col items-center justify-center`}>

    {fullwidth && <div className={`max-w-2xl w-full h-full border-red-700 rounded`} style={{
      borderWidth: '10px'
    }}>
      &nbsp;
  </div>}

    {!fullwidth &&
      <div className={`flex flex-col items-center justify-center`}>
        <div className={`w-60 h-60 border-4 border-red-600 rounded`}>&nbsp;</div>
        <div className={`bg-red-600 rounded font-bold text-white px-4 pt-1 pb-2 text-sm`}>Hold over QR code</div>
      </div>}


  </div>
}

export default PositionOverlay