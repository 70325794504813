import {
  Link,
} from "react-router-dom"

function Header() {
  return <div className={`-rotate-3 scale-105 border-t-4 border-b-4 border-yellow-400 bg-yellow-400 flex flex-row justify-center items-center shadow-lg mt-4`}>
    <div className={`hidden md:block`} style={{
      height: '70px',
      width: '35%',
      background: 'url(/warningtape.svg)',
      backgroundSize: 'cover',
      backgroundPosition: 'top right'
    }}
    >&nbsp;</div>

    <div className={`bg-yellow-400 flex flex-col jusify-center items-center p-1 text-center`}
    style={{ width: `280px`}}>
      <p className={`text-3xl font-bungee text-black`}>WARNING</p>
      <p className={`text-sm font-bungee text-black`}>we told you not to scan this</p>
    </div>
    <div className={`hidden md:block`} style={{
      height: '70px',
      width: '40%',
      background: 'url(/warningtape.svg)',
      backgroundSize: 'cover',
      backgroundPosition: 'top left'
    }}
    >&nbsp;</div>
  </div>
}

export default Header