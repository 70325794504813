import Children from 'react'

type Props = {
  children: any
  setShowModal?: React.Dispatch<React.SetStateAction<any>>
  showCloseButton?: boolean
  closeButtonValue?: any
}

function Modal({
  children,
  setShowModal,
  showCloseButton,
  closeButtonValue
}: Props) {

  return <div className="absolute inset-0 flex items-center justify-center bg-gray-700 bg-opacity-70 min-h-full z-50 p-6">
    <div className="rounded-xl w-full max-w-sm p-6 bg-white divide-y divide-gray-500 shadow-2xl max-h-full overflow-y-auto relative">



      {setShowModal && showCloseButton && <div className={`absolute right-0 top-0 border-none mb-3 cursor-pointer bg-brandgreen hover:opacity-80 m-2 w-6 h-6 font-white flex items-center justify-center`} style={{
        borderRadius: '50%',
        padding: '2px'
      }} onClick={() => setShowModal(null)}>

        <img className={`w-3 h-3`} src='/close-x.svg' />
      </div>}



      <div className={`border-none`}>
        {children}
      </div>
    </div>
  </div>
}
export default Modal